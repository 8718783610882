@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: #dfe9f5;
}
.register-box {
  width: 350px;
  padding: 2rem 0 1rem 0;
  margin: 50px auto;
  background: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.register-h1 {
  font-size: 2rem;
  color: black;
}
.register-p {
  margin-bottom: 1.7rem;
}
.register-input {
  width: 85%;
  outline: none;
  border: none;
  background: antiquewhite;
  padding: 12px 14px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.register-button {
  font-size: 1.2rem;
  margin-top: 1rem;
  padding: 8px;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 85%;
  background: red;
  color: #fff;
  cursor: pointer;
}
.register-button:hover {
  background: brown;
}
.member {
  font-size: 0.8rem;
  margin-top: 1.4rem;
}
.memberButton {
  color: red;
}

.memberButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
